<template>
  <div class="ranking-wrap">
    <faceForm @getFormParams="getFormParams"></faceForm>
    <div class="section statistics-wrap">
      <ul v-if="statistics.length" class="list">
        <li class="item" v-for="item in statistics">
          <div class="value"><countTo :startVal="0" :endVal="item.amount" :decimals="2" :duration="1500"></countTo></div>
          <div class="label">{{item.name}}（{{item.unit}}）</div>
        </li>
      </ul>
      <empty :show="!statistics.length" text="暂无统计数据"></empty>
    </div>
    <div class="section sort-wrap">
      <div class="header">
        <span class="title">门店排行前十</span>
        <span class="unit">单位：人次</span>
      </div>
      <empty :show="!firstList.length" text="暂无排行数据"></empty>
      <ul class="list" v-if="firstList.length">
        <li class="item" v-for="item in firstList">
          <div class="icon">
            <img v-if="item.rank <= 3" :src="icon(item.rank, 'desc')" alt="">
            <div class="ranking" v-else>{{item.rank}}</div>
          </div>
          <div class="info-wrap">
            <div class="name">{{item.regionName}}</div>
            <div class="amount">{{item.amount}}</div>
            <div class="progress"><el-progress :show-text="false" :percentage="item.percentage * 1"></el-progress></div>
          </div>
        </li>
      </ul>
    </div>
    <div class="section sort-wrap">
      <div class="header">
        <span class="title">门店排行后十</span>
        <span class="unit">单位：人次</span>
      </div>
      <empty :show="!secondList.length" text="暂无排行数据"></empty>
      <ul class="list" v-if="secondList.length">
        <li class="item" v-for="item in secondList">
          <div class="icon">
            <img v-if="item.rank <= 3" :src="icon(item.rank, 'asc')" alt="">
            <div class="ranking" v-else>{{item.rank}}</div>
          </div>
          <div class="info-wrap">
            <div class="name">{{item.regionName}}</div>
            <div class="amount">{{item.amount}}</div>
            <div class="progress"><el-progress :show-text="false" :percentage="item.percentage * 1"></el-progress></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import faceForm from "./form"
  import countTo from "vue-count-to"
  import empty from "@/components/empty"
  import Api from "@/views/face/api";
  export default {
    name: "ranking",
    components: {
      empty,
      faceForm,
      countTo
    },
    data: () => ({
      params: {
        store: '',
        period: '',
        gender: '',
        age_type: '',
        start_date_time: '',
        end_date_time: '',
        order: 'desc',
        page_no: 1,
        page_size: 10
      },
      statistics: [],
      firstList: [],
      secondList: []
    }),
    methods: {
      getFormParams(params) {
        Object.keys(params).forEach(key => this.params[key] = params[key])
        // console.log(this.params)
        this.getStatistics()
      },
      getStatistics() {
        Api.ranking(this.params).then(res => {
          if (res.data) {
            const {averageCustomer, rankCount, totalCustomer, firstList, secondList} = res.data
            this.statistics = [
              {name: '参与排行门店', unit: rankCount.unit, amount: rankCount.amount === '-' ? 0 : rankCount.amount.replaceAll(',', '') * 1},
              {name: '客流量', unit: totalCustomer.unit, amount: totalCustomer.amount === '-' ? 0 : totalCustomer.amount.replaceAll(',', '') * 1},
              {name: '平均客流量', unit: averageCustomer.unit, amount: averageCustomer.amount === '-' ? 0 : averageCustomer.amount.replaceAll(',', '') * 1},
            ]
            this.firstList = firstList || []
            this.secondList = secondList || []
          } else {
            this.statistics = []
            this.firstList = []
            this.secondList = []
          }
        }).catch(err => {
          console.log(err);
        })
      },
      // 设置排名图标
      icon(rank, sort) {
        return require(`@/assets/${sort}_${rank}.png`)
      }
    }
  }
</script>

<style scoped lang="scss">
  .statistics-wrap {
    padding: 0;
    .list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      padding: 0;
      .item {
        position: relative;
        padding: 20px;
        width: 100%;
        height: 100%;
        text-align: center;
        box-sizing: border-box;
        border-bottom: 1px solid #EEE;
        &:last-of-type {
          border-bottom: none;
          grid-column-start: 1;
          grid-column-end: 3;
        }
        .label {
          color: #AAA;
          font-size: 14px;
          margin-top: 10px;
        }
        .value {
          color: #333;
          font-size: 24px;
        }
      }
    }
  }
  .sort-wrap {
    padding: 10px 20px;
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 0;
      .title {
        color: #000;
        font-size: 18px;
      }
      .unit {
        color: #888;
        font-size: 12px;
      }
    }
    .list {
      .item {
        display: flex;
        align-items: center;
        padding: 10px 0;
        .icon {
          width: 32px;
          height: 32px;
          line-height: 32px;
          margin-right: 10px;
          .ranking {
            width: 28px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            border-radius: 50%;
            margin: 0 auto;
            background-color: #EEE;
          }
        }
        .info-wrap {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          justify-content: space-between;
          flex: 1;
          .name {
            flex: 1;
            color: #333;
            font-size: 16px;
          }
          .amount {
            font-size: 14px;
            color: #888;
          }
          .progress {
            width: 100%;
            margin-top: 10px;
          }
        }
      }
    }
  }
</style>